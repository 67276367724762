import PropTypes from 'prop-types';

import { MenuItem, TextField } from '@mui/material';

import { noOp } from 'utils/constants';

function SelectField({ menuItems, name, label, handleChange, value, ...props }) {
  return (
    <TextField
      select
      label={label}
      name={name}
      onChange={e => handleChange(e.target.value)}
      value={value}
      {...props}
    >
      {menuItems.map(menuItem => {
        const isIntegerOrString =
          typeof menuItem === 'string' || menuItem instanceof String || Number.isInteger(menuItem);
        const itemObj = isIntegerOrString ? { value: menuItem, label: menuItem } : menuItem;
        return (
          <MenuItem value={itemObj.value} key={itemObj.value}>
            {itemObj.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

const itemPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
  PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  }),
]);

SelectField.propTypes = {
  menuItems: PropTypes.arrayOf(itemPropType),
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

SelectField.defaultProps = {
  handleChange: noOp,
  value: '',
  menuItems: [],
};

export default SelectField;
