import React, { useState } from 'react';
import toast from 'react-hot-toast';

import { parseISO } from 'date-fns';
import { Form, Formik, getIn } from 'formik';
import { useRouter } from 'next/router';
import { any, arrayOf, bool, func, node, object, oneOfType, shape, string } from 'prop-types';
import * as YUP from 'yup';

import { makeStyles } from '@bequestinc/wui';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Stack } from '@mui/material';

const useStyles = makeStyles()(() => ({
  defaultForm: {
    paddingBlock: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    margin: '0 auto',
    maxWidth: 'clamp(200px,80%,700px)',
  },
}));

export function parseDateValue(date) {
  if (!date) {
    return null;
  }
  // the date is a date object if chosen from the date picker
  if (date instanceof Date) {
    return date;
  }
  // the date is a string if it's coming from the backend
  return parseISO(date);
}

const NON_FIELD_ERRORS = 'nonFieldErrors';

const hydrateForm = (element, getFieldProps, hasError, getHelperText, setFieldValue, readOnly) => {
  if (!React.isValidElement(element)) {
    return element;
  }

  const newChildren = React.Children.map(element.props.children, el =>
    hydrateForm(el, getFieldProps, hasError, getHelperText, setFieldValue, readOnly),
  );

  const newProps = { ...element.props, children: newChildren };

  if (Object.prototype.hasOwnProperty.call(newProps, 'name')) {
    const formikProps = getFieldProps(element.props.name);
    Object.assign(newProps, {
      ...formikProps,
      onChange: (e, autocompleteValue = null) => {
        // This gives fields rendered in the form the opportunity to perform
        // side effects or transform the event object before it is handled here.
        if (element.props.onChange) {
          element.props.onChange(e, setFieldValue, autocompleteValue);
        }

        if (element.props.type === 'checkbox-group') {
          if (e.target.checked) {
            const values = [...formikProps.value];
            values.push(e.target.value);
            setFieldValue(element.props.name, values);
          } else {
            const values = formikProps.value.filter(val => val !== e.target.value);
            setFieldValue(element.props.name, values);
          }
        } else if (element.props.type === 'date') {
          setFieldValue(element.props.name, e);
        } else if (element.props.type === 'checkbox') {
          setFieldValue(element.props.name, e.target.checked);
        } else if (element.props.type === 'autocomplete') {
          setFieldValue(element.props.name, autocompleteValue);
        } else if (element.props.type === 'file') {
          setFieldValue(element.props.name, e);
        } else {
          setFieldValue(element.props.name, e.target.value);
        }
      },
      /* we don't want to add error/helpertext here as it the autocomplete component works a bit differently
      and is handled within the component itself */
      ...(element.props.type !== 'autocomplete' && { error: hasError(element.props.name) }),
      ...(element.props.type !== 'autocomplete' && {
        helperText: element.props.helperText || getHelperText(element.props.name),
      }),

      ...(element.props.type === 'checkbox' && {
        checked: formikProps.value,
        // checkboxes don't have a 'readOnly' prop, so we pass 'disabled' instead
        disabled: element.props.readOnly || readOnly,
      }),
      ...(element.props.type === 'checkbox-group' && {
        // FormControlLabel don't have a 'readyOnly' prop, so we pass 'disabled' instead
        disabled: element.props.readOnly || readOnly,
      }),
      ...(element.props.type === 'date' && { value: parseDateValue(formikProps.value) }),

      // if there is a type, we can pass readonly this way
      ...(element.props.type && { readOnly: element.props.readOnly || readOnly }),
      // if we don't have a type, it's a text / select field and we want to pass readonly this way
      ...(!element.props.type && {
        InputProps: {
          ...element.props?.InputProps,
          readOnly: element.props.readOnly || readOnly,
        },
      }),
    });
  }

  return React.cloneElement(element, newProps);
};

function CustomForm({
  children,
  initialValues,
  handleFormSubmit,
  validationSchema,
  shouldReset,
  showSuccess,
  className,
  submitButtonText,
  readOnly,
  doNotRenderButtons,
  resetAction,
  ...props
}) {
  const { classes } = useStyles();
  const [serverErrors, setServerErrors] = useState({});
  const router = useRouter();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validationSchema={readOnly ? null : validationSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await handleFormSubmit(values);

          // reset server errors after successful submission
          setServerErrors({});

          if (shouldReset) {
            resetForm();
          }
          if (showSuccess) {
            toast.success('Success!');
          }
        } catch (error) {
          const formFields = Object.keys(initialValues);

          const newErrors = error?.response?.data;

          if (!newErrors || typeof newErrors !== 'object') {
            setServerErrors({ nonFieldErrors: 'An unknown error occurred' });
            return;
          }

          if (formFields.some(field => newErrors[field])) {
            setServerErrors(error.response.data);
          } else {
            setServerErrors({ nonFieldErrors: Object.values(newErrors)[0] });
          }
        }
      }}
      {...props}
    >
      {({
        errors,
        touched,
        getFieldProps,
        setFieldValue,
        resetForm,
        isSubmitting,
        dirty,
        isValid,
      }) => {
        const hasError = fieldName =>
          Boolean(getIn(errors, fieldName) && getIn(touched, fieldName)) ||
          Boolean(serverErrors[fieldName]);

        const getHelperText = fieldName =>
          getIn(errors, fieldName) && getIn(touched, fieldName)
            ? getIn(errors, fieldName)
            : serverErrors[fieldName]?.[0] ?? '';

        const childrenWithProps = React.Children.map(children, child =>
          hydrateForm(child, getFieldProps, hasError, getHelperText, setFieldValue, readOnly),
        );

        return (
          <>
            {NON_FIELD_ERRORS in serverErrors && (
              <p style={{ textAlign: 'center', color: 'red', marginBottom: 16 }}>
                {serverErrors[NON_FIELD_ERRORS]}
              </p>
            )}
            <Form className={className ?? classes.defaultForm}>
              {childrenWithProps}
              {!readOnly && !doNotRenderButtons && (
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  {resetAction && (
                    <Button
                      fullWidth
                      variant="outlined"
                      onClick={() => {
                        if (resetAction.cancelRedirectPath) {
                          router.push(resetAction.cancelRedirectPath);
                        } else {
                          resetForm();
                        }
                      }}
                      disabled={isSubmitting}
                      sx={{ textTransform: 'none' }}
                    >
                      {resetAction?.cancelButtonText || 'Cancel'}
                    </Button>
                  )}
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting || readOnly || !dirty || !isValid}
                    loading={isSubmitting}
                    sx={{ textTransform: 'none' }}
                  >
                    {submitButtonText}
                  </LoadingButton>
                </Stack>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
}

export default CustomForm;

CustomForm.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  initialValues: object.isRequired,
  handleFormSubmit: func.isRequired,
  validationSchema: any,
  shouldReset: bool,
  showSuccess: bool,
  className: string,
  submitButtonText: string,
  readOnly: bool,
  doNotRenderButtons: bool,
  resetAction: shape({
    cancelRedirectPath: string,
    cancelButtonText: string,
  }),
};

CustomForm.defaultProps = {
  validationSchema: YUP.object().shape({}),
  shouldReset: true,
  showSuccess: true,
  className: null,
  submitButtonText: 'Submit',
  readOnly: false,
  doNotRenderButtons: false,
  resetAction: null,
};
