import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

const ChipSelector = ({
  options,
  value,
  onChange,
  label,
  textFieldStyle,
  disabled,
  error,
  helperText,
  ...props
}) => (
  <Autocomplete
    multiple
    options={options}
    getOptionLabel={option => (typeof option === 'string' ? option : option[1])}
    filterSelectedOptions
    value={value}
    disabled={disabled}
    disableCloseOnSelect
    onChange={onChange}
    isOptionEqualToValue={(option, v) => option === (v || v.toString())}
    renderInput={params => (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        error={error}
        helperText={helperText}
        className={textFieldStyle}
      />
    )}
    {...props}
  />
);

ChipSelector.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  textFieldStyle: PropTypes.string,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

ChipSelector.defaultProps = {
  textFieldStyle: '',
  disabled: false,
  helperText: '',
  error: false,
};

export default observer(ChipSelector);
