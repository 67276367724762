import { useQueries, useQuery } from '@tanstack/react-query';

import { getFirmById, getFirmOptions, getFirms, getLocationCodes, getMasterFileFirms } from 'api';

import { DAY_IN_MILLISECONDS } from 'utils/constants';

export const FirmsQueryKey = ['firms'];
export const MasterFileFirmsQueryKey = ['master-file-firms'];
export const locationCodesQueryKey = ['all-location-codes'];
export const firmOptionsQueryKey = ['firm-options'];

export const useAllFirmsQuery = (params, override, includeMasterFileFirms = false) => {
  const queries = [
    {
      queryKey: FirmsQueryKey.concat(params),
      queryFn: () => getFirms(params),
      ...override,
    },
  ];

  if (includeMasterFileFirms) {
    queries.push({
      queryKey: MasterFileFirmsQueryKey.concat(params),
      queryFn: () => getMasterFileFirms(params),
      ...override,
    });
  }

  return useQueries({
    queries,
  });
};

export const useLocationCodesQuery = (override = {}) =>
  useQuery({
    queryKey: locationCodesQueryKey,
    queryFn: getLocationCodes,
    select: requestData =>
      requestData.map(code => [code.locationCode.toString(), code.codeAndDescription]),
    staleTime: DAY_IN_MILLISECONDS,
    ...override,
  });

export const useFirmQuery = (id, override = {}) =>
  useQuery({
    queryKey: FirmsQueryKey.concat(id),
    queryFn: () => getFirmById(id),
    ...override,
  });

export const useFirmOptionsQuery = (override = {}) =>
  useQuery({
    queryKey: firmOptionsQueryKey,
    queryFn: getFirmOptions,
    ...override,
  });
